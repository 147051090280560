<template>
  <vx-card title="Sort Menu">
    <div class="vx-row mb-12 mr-auto ml-auto">
      <div v-bind:class="[detail ? detailShow : '', detailHide]">
        <span class="mb-5">
          <b>Main Menu</b>
        </span>
        <vs-table
          class="mt-5"
          stripe
          border
          description
          :sst="true"
          :data="table.data"
        >
          <!-- <template slot="thead">
            <vs-th width="5%"></vs-th>
            <vs-th>Display Name</vs-th>
            <vs-th>Active</vs-th>
            <vs-th>Icon</vs-th>
          </template> -->

          <template>
            <draggable v-model="table.data">
              <transition-group>
                <vs-tr class="flex w-full" :key="indextr" v-for="(indextr, i) in table.data">
                  <vs-td :class="indextr.class" class="whitespace-no-wrap">
                    <feather-icon
                    title="Edit"
                    icon="EditIcon"
                    svgClasses="w-5 h-5 hover:text-primary stroke-current"
                    class="ml-2"
                    @click.stop="handleEdit(indextr.id, indextr.display_name, i)"
                    />
                  </vs-td>
                  <vs-td :class="indextr.class" class="cursor-move w-full">{{ indextr.display_name }}</vs-td>
                </vs-tr>
              </transition-group>
            </draggable>
          </template>
        </vs-table>
        <!-- <vs-pagination style="padding-top:5px" :total="table.totalPage" v-model="setPage" /> -->
        <div class="vx-row mt-3">
            <div class="vx-col w-full">
                <vs-button class="mr-3 mb-2" @click="handleSave">Save</vs-button>
            </div>
        </div>
      </div>
      <!-- form -->
      <transition name="detail-fade">
        <div v-if="detail" v-bind:class="[detail ? detailShow : '', detailHide]">
          <vs-row>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              
            </vs-col>
            <vs-col
              class="vertical-divider"
              vs-offset="0"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="1"
            >
            </vs-col>

            <!-- form component -->
            <form-component :id="id" :parent="parent"></form-component>
          </vs-row>
        </div>
      </transition>
    </div>
  </vx-card>
</template>
<script>
import FormComponent from "./sorting_form";
import draggable from 'vuedraggable'
export default {
  components: {
    FormComponent,
    draggable
  },
  created() {
      // this.getData()
  },
  data() {
    return {
      baseUrl: "/api/v1/setting/menu",
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/2 w-full mb-base ",
      detail: true,
      deleteId: null,
      id: null,
      parent: null,
      table: {
        data: []
      }
    };
  },
  methods: {
    paramData() {
      return {
        data: this.table.data
      };
    },
    handleEdit(id, parent, i) {
      this.id = id;
      this.parent = parent;
      this.detail = true;
      this.table.data.forEach(function(element){
        element.class = ""
      })
      this.table.data[i]["class"] = "selected-row"
      // this.getData1()
    },
    handleSave() {
      this.putData()
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl, {
          params: {
            order: "sort",
            sort: "ASC"
          }
        })
        .then(resp => {
          if (resp.status == "success") {
            this.table.data = []
            var x = []
            resp.data.records.forEach(function(element, index){
                if(element.parent_id == 0){
                  x.push({
                    id: resp.data.records[index].id,
                    display_name: resp.data.records[index].display_name
                  })
                }
            })
            this.table.data = x
            this.$vs.loading.close();
          } else {
            console.log(resp.data);
          }
        });
    },
    getData1() {
       this.$vs.loading();
      this.$http
        .get(this.baseUrl, {
          params: {
            order: "sort",
            sort: "ASC"
          }
        })
        .then(resp => {
          if (resp.status == "success") {
            // this.table1.data = []
            var _this = this
            var x = []
            resp.data.records.forEach(function(element, index){
              if(element.parent_id == _this.id){
                x.push({
                  id: resp.data.records[index].id,
                  display_name: resp.data.records[index].display_name
                })
              }
            })
            this.table.data = x
            this.$vs.loading.close();
          } else {
            console.log(resp.data);
          }
        });
    },
    putData() {
      this.$http
        .put("/api/v1/setting/sort-menu", this.paramData())
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Main Menu Updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
  },
  watch: {
    "table.data": function() {
      var _this = this
      this.table.data.forEach(function(element, index){
        _this.table.data[index]["sort"] = index+1
      })
      
    }
  },
  mounted() {
    this.getData()
  },
  computed: {}
};
</script>

 <style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
.selected-row {
  background-color: #d0cdf0;
}
</style>