<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span class="mb-5">
      <b>Submenu {{parent}}</b>
    </span>
    <vs-table
        class="mt-5"
        stripe
        border
        description
        :sst="true"
        :data="table1.data"
    >
        <template>
            <draggable v-model="table1.data">
                <transition-group>
                    <vs-tr class="flex w-full" v-for="indextr in table1.data" :key="indextr">
                        <vs-td class="cursor-move w-full">{{ indextr.display_name }}</vs-td>
                    </vs-tr>
                </transition-group>
            </draggable>

        </template>
    </vs-table>
    <div class="vx-row mt-3">
        <div class="vx-col w-full">
            <vs-button v-if="table1.data.length > 0" class="mr-3 mb-2" @click="handleSave">Save</vs-button>
        </div>
    </div>
    
  </div>
</template>
<script>
import draggable from 'vuedraggable'
export default {
  components: {
    draggable
  },
  props: {
    id: {
      type: Number
    },
    parent: {
      type: String
    }
  },
  created() {
    this.getIcon();
  },

  data() {
    return this.initialState();
  },
  methods: {
    setSelected(value) {
      this.data.icon = value.class;
      this.data.icon_name = value.name;
      // console.log(value.class)
    },
    initialState() {
      return {
        menuId: null,
        options: {
          icons: [],
          search: '',
          offset: 0,
          limit: 10,
        },
        baseUrl: "/api/v1/setting/menu",
        data: {
          parentId: null,
          name: "",
          displayName: "",
          path: "",
          icon: "",
          icon_name: "",
          active: true
        },
        parentMenu: {
          selected: null,
          options: []
        },
        table1: {
            data: []
        },
        // parent: ""
      };
    },
    paramData() {
      return {
        data: this.table1.data
      };
    },
    handleSave() {
      this.putData()
    },
    postData() {
      this.$http
        .post(this.baseUrl, this.paramData())
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "New Menu Created",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl, {
          params: {
            order: "sort",
            sort: "ASC"
          }
        })
        .then(resp => {
          if (resp.status == "success") {
            this.table1.data = []
            var _this = this
            var x = []
            resp.data.records.forEach(function(element, index){
              if(element.parent_id == _this.id){
                x.push({
                  id: resp.data.records[index].id,
                  display_name: resp.data.records[index].display_name
                })
              }
            })
            this.table1.data = x
            this.$vs.loading.close();
          } else {
            console.log(resp.data);
          }
        });
    },
    putData() {
      this.$http
        .put("/api/v1/setting/sort-menu", this.paramData())
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Submenu Updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
  },
  mounted() {

  },
  computed: {

  },
  watch: {
    "id": function() {
      this.getData();
    },
    "table1.data": function() {
      var _this = this
      this.table1.data.forEach(function(element, index){
        _this.table1.data[index]["sort"] = index+1
      })
    }
  }
};
</script>
<style scoped>
  .pagination {
    display: flex;
    margin: .25rem .25rem 0;
  }
  .pagination button {
    flex-grow: 1;
  }
  .pagination button:hover {
    cursor: pointer;
  }
</style>
